<template>
  <!-- eslint-disable -->
  <v-container id="register" class="px-0" fluid tag="section">
    <v-row class="register-form-row mx-0" align="center" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" sm="10" md="8" lg="6">
        <v-card class="register-form-card px-3 py-5" ref="form">
          <v-col>
            <h1 class="display-1 text-left pb-3 mb-3">Administrator Registration</h1>
            <v-form ref="form" @submit.prevent="regFn">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    ref="name"
                    v-model="userName"
                    label="Name"
                    outlined
                    :error-messages="errorUserName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    ref="emailAddress"
                    v-model="emailAddress"
                    label="Email"
                    outlined
                    :rules="[() => !!checkEmailFn(emailAddress) || 'This email is invalid ']"
                    :error-messages="errorEmailAddress"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    type="password"
                    ref="Password"
                    v-model="password"
                    label="Password"
                    outlined
                    :error-messages="errorPassword"
                  ></v-text-field>
                  <!-- :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                  @click:append="show = !show"-->
                </v-col>
              </v-row>
              <!-- <v-row align="center" justify="center">
                <span style="color: red;">{{mainerror}}</span>
              </v-row>-->

              <!-- :loading="isApiLoad" -->
              <v-row align="center" justify="center">
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    type="password"
                    ref="key"
                    v-model="secKey"
                    label="Secret Key"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mx-auto mb-3 mt-n1 error-row">
                <span class="caption text-center error--text">{{mainError}}</span>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="6" class="d-flex flex-column align-center justify-space-between py-0">
                  <v-btn
                    type="submit"
                    class="px-12 mb-3 mx-auto"
                    color="secondary"
                    tile
                    depressed
                    bottom
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import CheckEmail from "../../components/util/CheckEmail";
import adminsApicalls from "../../services/admin.sevice";

export default {
  name: "Register",
  data() {
    return {
      userName: null,
      emailAddress: null,
      password: null,
      mainError: null,
      // secKey: null,

      errorUserName: null,
      errorEmailAddress: null,
      errorPassword: null,
      // errorSecKey: null,
      staticErrorMessages: {
        userName: {
          isEmpty: "User Name Cannot be empty"
        },
        emailAddress: {
          isEmpty: "Email Address Cannot be empty"
        },
        password: {
          isEmpty: "Please Set Your Password"
        }
        // secKey: {
        //   isEmpty: "You Must Provide The Secret Key"
        // }
      }
    };
  },
  watch: {
    userName() {
      this.errorUserName = null;
    },
    emailAddress() {
      this.errorEmailAddress = null;
    },
    password() {
      this.errorPassword = null;
    }
    // secKey() {
    //   this.errorSecKey = null;
    // }
  },
  methods: {
    checkEmailFn(emailAddress) {
      return CheckEmail(emailAddress);
    },
    regFn() {
      if (this.userName && this.emailAddress && this.password) {
        let payload = {
          Name: this.userName,
          Email: this.emailAddress,
          Password: this.password,
          secKey: this.secKey,
          // adminType: "master"
        };
        adminsApicalls.masterAdmin(payload).then(res => {
          if (res.data.message == "success") {
            let payload2 = {
              snackBarText: " Admin created successfully",
              snackBarColor: "success"
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            this.$router.push("login");
          } else if (res.data.message == "failed") {
            // this.isApiLoad = false;
            this.mainError = res.data.details;
            // this.newPwd = null;
            // this.currentPwd = null;
            // this.confirmPwd = null;
            setTimeout(() => {
              this.mainError = null;
            }, 2000);
            
          }
        });
        // (this.userName = null),
        //   (this.emailAddress = null),
        //   (this.password = null);
        // this.$router.push("login");
      } else {
        if (!this.userName) {
          this.errorUserName = this.staticErrorMessages.userName.isEmpty;
        }
        if (!this.emailAddress) {
          this.errorEmailAddress = this.staticErrorMessages.emailAddress.isEmpty;
        }
        if (!this.password) {
          this.errorPassword = this.staticErrorMessages.password.isEmpty;
        }
        // if (!this.secKey) {
        //   this.errorSecKey = this.staticErrorMessages.secKey.isEmpty;
        // }
      }
    }
  }
};
</script>
<style>
#register {
  background-image: linear-gradient(
      to left,
      rgba(53, 167, 255, 0.3),
      rgba(53, 167, 255, 0.3)
    ),
    url("https://source.unsplash.com/w1g2o4J_4Dg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.register-form-card {
  position: relative;
  width: 600px;
}
.register-form-row {
  /* position: absolute; */
  top: 0;
  min-width: 100vw;
  /* the 64px is to offset the top app bar */
  height: calc(100vh - 64px);
  min-height: 300px;
}
.register-image-credits {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
}
.register-image-credits a {
  color: white;
}
.register-image-credits a:hover {
  color: gray;
}
/* Medium devices (large tablet to laptop, less than 960px) */
@media (max-width: 960px) {
  .register-form-card {
    width: 80vw;
    min-width: 200px;
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .register-cover-image {
    height: calc(100vh - 56px);
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .register-form-row {
    height: calc(100vh - 56px);
  }
}
</style>